<template>
  <div class="ai-menubar" v-show="isMenubar">
    <div
      :class="['item', index == tabIndex ? 'active' : '']"
      @click="changeTab(index)"
      v-for="(item, index) in tabList"
      :key="index"
      @mouseenter="mouseEnterTab(index)"
      @mouseleave="mouseLeaveTab(index)"
    >
      <img :src="item.icon" alt="" class="img-icon" />
      <div :class="['item-title', index == tabIndex ? 'active' : '']">
        {{ item.title }}
      </div>
      <p class="item-txt txt-cut3">{{ item.txt }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "aiMenubar",
  props: {
    isMenubar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabIndex: null,
      tabList: [
        {
          icon: require("@/assets/image/ai/index/ybsj.png"),
          title: "预报时间",
          txt: "根据当前病虫基数、作物长势、气象预报及天敌等情况，结合历史资料，经充分会商，省农技中心对下半年我省农作",
        },
        {
          icon: require("@/assets/image/ai/index/bchzd.png"),
          title: "病虫害诊断",
          txt: "根据当前病虫基数、作物长势、气象预报及天敌等情况，结合历史资料，经充分会商，省农技中心对下半年我省农作",
        },
        {
          icon: require("@/assets/image/ai/index/szzl.png"),
          title: "数字治理",
          txt: "根据当前病虫基数、作物长势、气象预报及天敌等情况，结合历史资料，经充分会商，省农技中心对下半年我省农作",
        },
        {
          icon: require("@/assets/image/ai/index/bczzsk.png"),
          title: "病虫害知识库",
          txt: "根据当前病虫基数、作物长势、气象预报及天敌等情况，结合历史资料，经充分会商，省农技中心对下半年我省农作",
        },
        {
          icon: require("@/assets/image/ai/index/nyzjdh.png"),
          title: "农业专家对话",
          txt: "根据当前病虫基数、作物长势、气象预报及天敌等情况，结合历史资料，经充分会商，省农技中心对下半年我省农作",
        },
      ],
    };
  },
  methods: {
    changeTab(index) {
      switch (index) {
        // case 1:
        //   // this.$router.push({ name: "chemical", query: { id: 864 } });
        //   break;
        case 3:
          // this.$router.push("/know");
          this.$router.push("/knowledgeBase");

          break;
        case 4:
          this.$router.push("/dialogue");
          break;

        default:
          this.$emit("changeTab", index);
          break;
      }
    },
    mouseEnterTab(index) {
      this.tabIndex = index;
    },
    mouseLeaveTab() {
      this.tabIndex = null;
    },
  },
};
</script>

<style lang="less" scoped>
.ai-menubar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .item {
    width: 221px;
    height: 284px;
    cursor: pointer;
    margin-right: 57px;
    text-decoration: none;
    transition: all 0.3s linear;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 22px 0;
    background: url("../../../assets/image/ai/index/item_bg.png") center center
      no-repeat;
    background-size: cover;
    &:last-of-type {
      margin: 0;
    }
    &.active {
      transform: scale(1.1) translateY(-30px);
      background: url("../../../assets/image/ai/index/item_active.png") center
        center no-repeat;
      background-size: cover;
    }
    .img-icon {
      width: 88px;
      height: 88px;
      margin-bottom: 18px;
    }
    .item-title {
      width: 153px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      font-size: 18px;
      margin-bottom: 18px;
      font-weight: bold;
      color: #fff;
      background: url("../../../assets/image/ai/index/title_bg.png") center
        center no-repeat;
      background-size: cover;
      &.active {
        background: url("../../../assets/image/ai/index/title_active.png")
          center center no-repeat;
        background-size: cover;
      }
    }
    .item-txt {
      width: 100%;
      font-size: 10px;
      font-weight: 300;
      color: #fff;
    }
  }
}
</style>
